<script setup lang="ts">
//dependencies
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

//store
import { useOrderStore, useLoaderStore } from "@/store";
import {
	markOrderCompleted,
	addTransactionLogs,
	createDeliveryInvoice,
} from "@/services/order.service";
import OrderCard from "@/components/steps/OrderCard.vue";
import { addLimitPurchaseOrderLogs, fetchInvoicedItemsByTaskId, fetchLimitPurchaseOrder, updateLpoQty } from "@/services/lpo.service";
import { Invoiced_Item, Limit_Purchase_Order } from "@/sdk";

const router = useRouter();
const orderStore = useOrderStore();
const loaderStore = useLoaderStore();
const rate = ref<number>(0);
const disableButton = ref<boolean>(false);

const invoiceCreated = ref<any>();
const transactionLogCreated = ref<any>();
const orderCompletedMarked = ref<any>();
const filteredTankProductVarId = ref<any>();

const calculateTotalQuantityInvoiced = (invoiced_items: Invoiced_Item[]) => {
	return invoiced_items.reduce((acc, curr) => acc + curr.actual_qty,0);
}

const nextStep = async () => {
	disableButton.value = true;
	loaderStore.toggleLoader({type: 'common', state: true});

	try {
		if (
			!invoiceCreated.value &&
			localStorage.getItem(
				"invoice_created/" + orderStore.getCurrentOrder.id,
			) !== "true"
		) {
			await createInvoiceOnly();
		}

		if (orderStore.isLimitPurchaseOrder) {
			const limitPurchaseOrder = (await fetchLimitPurchaseOrder(orderStore.getCurrentOrder?.customer_order.organization_user.organization_id))
				?.limit_purchase_order as Partial<Limit_Purchase_Order[]>;
			const invoicedItems = (await fetchInvoicedItemsByTaskId(orderStore.getCurrentOrder?.id))
				?.invoiced_item as Invoiced_Item[];
			const sum = calculateTotalQuantityInvoiced(invoicedItems);
			const addressId = limitPurchaseOrder?.find((lpo) => lpo?.organization_address_id === orderStore.getCurrentOrder.organization_address?.id)
				?.organization_address_id;

			const res = await updateLpoQty({
				orgId: orderStore.getCurrentOrder?.customer_order.organization_user.organization_id,
				orgAddressId: addressId ?? null,
				quantity: -sum
			});

			const lpoId = res?.update_limit_purchase_order?.returning[0]?.id;

			if (lpoId) {
				const invoiceData = invoicedItems.map(item => ({
					invoiced_item_id: item.id,
					quantity: item.actual_qty,
					limit_purchase_order_id: lpoId
				}));
				await addLimitPurchaseOrderLogs(invoiceData);
			}
		}

		filteredTankProductVarId.value =
			orderStore?.getDriverVehicleDetails?.vehicle_tank_types
				.filter((tank: any) => tank.tank_type.slug === "browser-tank")
				.map(
					(tank: any) =>
						tank.vehicle_tank_type_product_variations[0]
							?.product_variation?.id,
				);

		if (
			!transactionLogCreated.value &&
			localStorage.getItem(
				"transaction_logs_added/" + orderStore.getCurrentOrder.id,
			) !== "true"
		) {
			await addTransactionLogsOnly();
		}

		await markOrderCompletedOnly();
		orderStore.resetStore();
		router.replace("/dashboard");
	} catch (error) {
		console.error("Error in completing order:", error);
		alert("Error in completing order. Try again.");
		throw new Error("Error completing order", { cause: error });
	} finally {
		loaderStore.toggleLoader({type: 'common', state: false});
		disableButton.value = false;
	}
};

async function createInvoiceOnly() {
	try {
		invoiceCreated.value = await createDeliveryInvoice({
			task_id: orderStore.getCurrentOrder?.id
		});

		localStorage.setItem(
			"invoice_created/" + orderStore.getCurrentOrder.id,
			"true",
		);
	} catch (e) {
		throw new Error("error creating invoice");
	}
}
async function markOrderCompletedOnly() {
	try {
		orderCompletedMarked.value = await markOrderCompleted(
			orderStore.getCurrentOrder.id,
		);

		localStorage.setItem(
			"order_marked_delivered/" + orderStore.getCurrentOrder.id,
			"true",
		);
	} catch (e) {
		throw new Error("Error marking order delivered");
	}
}
async function addTransactionLogsOnly() {
	try {
		transactionLogCreated.value = await addTransactionLogs({
			quantity: orderStore.getDispensedQty,
			product_variation_id: filteredTankProductVarId.value[0],
			fillup_request_id: null,
			customer_order_id:
				orderStore.getCurrentOrder.customer_order?.id,
			vehicle_id: orderStore.getDriverVehicleDetails?.id,
			transaction_type: "OUT",
		});

		localStorage.setItem(
			"transaction_logs_added/" + orderStore.getCurrentOrder.id,
			"true",
		);
	} catch (e) {
		console.error("Error adding transaction logs:", e);
		throw new Error("Error adding transaction logs");
	}
}

onMounted(async () => {
	rate.value =
		orderStore.getCurrentOrder?.customer_order?.customer_order_items[0]?.unit_price;
});
//end-of-script
</script>
<template>
	<div
		class="container flex flex-col items-center justify-start min-h-[calc(100vh-64px)] space-y-4">
		<!-- Message Section -->
		 <OrderCard />
		<div
			class="message bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
			role="alert">
			<strong class="font-bold">Success!</strong>
			<span class="block sm:inline">
				Your order has been successfully updated. Please proceed to
				mark the order as complete. All associated assets have been
				properly filed.</span
			>
		</div>
		<!-- Button -->
		<button
			@click.prevent="nextStep"
			class="w-full btn btn-blue bg-[#069668] hover:bg-green-700"
			:disabled="disableButton"
			cl>
			Mark Order as Complete
		</button>
	</div>
</template>

<style scoped>
.container {
	padding: 20px;
}

.btn-blue:disabled {
	background-color: #6c757d;
}

.message {
	width: 100%;
	text-align: center;
}

.content {
	text-align: center;
}

.mt-4 {
	margin-top: 1rem;
}

.space-y-4 > * + * {
	margin-top: 1rem;
}
</style>
