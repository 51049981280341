import { Limit_Purchase_Order_Logs_Insert_Input } from "@/sdk";
import client from "./client";

export const fetchLimitPurchaseOrder = async (orgId: string) => {
	try {
		return await (
			await client
		).fetchLimitPurchaseOrder({
			orgId: orgId,
		});
	} catch (error) {
		console.error(error);
	}
};

export const fetchInvoicedItemsByTaskId = async (taskId: string) => {
	try {
		return await (
			await client
		).fetchInvoicedItemsByTaskId({
			taskId: taskId,
		});
	} catch (error) {
		console.error(error);
	}
};

export const updateLpoQty = async (args: {
	orgId: string;
	orgAddressId: string | null;
	quantity: number;
}) => {
	try {
		const whereCondition: any = {};

		if (args.orgAddressId) {
			whereCondition.organization_id = { _eq: args.orgId };
			whereCondition.organization_address_id = {
				_eq: args.orgAddressId,
			};
		} else {
			whereCondition.organization_id = { _eq: args.orgId };
		}

		return await (
			await client
		).updateLpoQty({
			_inc: { quantity: args.quantity },
			where: whereCondition,
		});
	} catch (error) {
		console.error(error);
	}
};

export const addLimitPurchaseOrderLogs = async (
	objects: Limit_Purchase_Order_Logs_Insert_Input[],
) => {
	try {
		return await (
			await client
		).addLimitPurchaseOrderLogs({
			objects: objects
		});
	} catch (err) {
		console.error(err);
	}
};
